<template>
  <div id="container">

    <section id="mypage" v-if="fetch_data">
      <div class="wrap">

        <!-- 現在のポイント残高 -->
        <div class="cts_box">
          <h2 class="sub_ttl"><span>ポイント通帳</span></h2>
          <p class="lead_txt">
            現在お持ちのポイント数とポイント獲得・ご利用実績をお知らせします。
          </p>

          <div class="bdr_box">
            <dl class="point_zandaka">
              <dt>現在のポイント残高</dt>
              <dd><span class="blue">{{ delimited(fetch_data.point.total_points) }}</span> ポイント</dd>
            </dl>
            <dl class="point_zandaka">
              <dt>次回失効ポイント</dt>
              <dd><span class="red">{{ fetch_data.point.expire ? delimited(fetch_data.point.expire.point) : 0}}</span> ポイント</dd>
            </dl>
            <dl class="point_zandaka">
              <dt class="sml">次回失効予定日</dt>
              <dd><span class="data">{{ fetch_data.point.expire ? moment(fetch_data.point.expire.date, 'YYYY年MM月DD日') : '-' }}</span></dd>
            </dl>
          </div>
        </div>

        <!-- ポイント履歴 -->
        <div class="cts_box">

          <table class="point_history">
            <tbody>
            <tr class="header">
              <th class="date_use">ポイント利用日</th>
              <th class="point_detail">内訳</th>
              <th class="number">増減したポイント数</th>
              <th class="date_ex">失効予定日</th>
            </tr>

            <template v-for="history in fetch_data.histories">
              <tr :key="history.id" v-if="history.point_type === 'point_type_expire'">
                <td class="date_use"><h3 class="sp">ポイント利用日</h3>{{ moment(history.created_at, 'YYYY年MM月DD日') }}
                </td>
                <td class="point_detail"><h3 class="sp">内訳</h3>
                  <p>{{ history.point_type_text }}</p></td>
                <td class="number red"><h3 class="sp">増減した<br>ポイント数</h3>{{ delimited(history.point) }}</td>
                <td class="date_ex"><h3 class="sp">失効予定日</h3>-</td>
              </tr>

              <tr :key="history.id" v-else-if="history.point > 0">
                <td class="date_use"><h3 class="sp">ポイント利用日</h3>{{ moment(history.created_at, 'YYYY年MM月DD日') }}
                </td>
                <td class="point_detail"><h3 class="sp">内訳</h3>
                  <p>{{ history.point_type_text }}</p></td>

                <td class="number blue"><h3 class="sp">増減した<br>ポイント数</h3>+{{ delimited(history.point) }}</td>
                <td class="date_ex"><h3 class="sp">失効予定日</h3>
                  <span v-if="history.expire_date">{{ moment(history.expire_date, 'YYYY年MM月DD日') }}</span>
                </td>
              </tr>

              <tr :key="history.id" v-else>
                <td class="date_use"><h3 class="sp">ポイント利用日</h3>{{ moment(history.created_at, 'YYYY年MM月DD日') }}
                </td>
                <td class="point_detail"><h3 class="sp">内訳</h3>
                  <p>{{ history.point_type_text }}</p></td>
                <td class="number"><h3 class="sp">増減した<br>ポイント数</h3>{{ delimited(history.point) }}</td>
                <td class="date_ex"><h3 class="sp">失効予定日</h3>-</td>
              </tr>

            </template>
            </tbody>
          </table>

          <!--pager-->
          <Pagination :pagination="fetch_data.pagination" v-on:changed="pageChanged"/>
          <!--//pager-->

          <div class="button_wrap">
            <router-link :to="{name: 'UsersCustomerAccount'}" class="submit arrow back large light skeleton">
              マイページに戻る
            </router-link>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>

import Libraries from '@/components/Libraries'
import Pagination from "@/components/Pagination";

export default {
  mixins: [Libraries],
  components: {
    Pagination
  },
  data() {
    return {
      fetch_data: null,
    }
  },
  watch: {
    '$route'() {
      this.fetch_data = null
      this.fetchData()
    }
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.startAjax()
      this.axios
          .get(`${this.env.api_base_url}users/point_histories.json`, {
            params: {
              page: this.$route.query.page
            }
          })
          .then(response => {
            this.fetch_data = response.data
          })
          .catch((response) => {
            this.errorAjax(response)
          })
          .finally(() => {
            this.finishAjax()
          })
    },
    pageChanged(page) {
      this.$router.push({
        name: 'UsersCustomerPoint',
        query: {page: page}
      })
    },
  },
}
</script>
